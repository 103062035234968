// Enum Declarations:
export enum SearchableField {
    Name = "Name",
    BrandLogoText = "BrandLogoText",
    KeywordName = "KeywordName",
    GoodsServicesContent = "GoodsServicesContent"
}

export enum FilterableField {
    Name = "Name",
    BrandLogoText = "BrandLogoText",
    RegistrationOfficeCode = "RegistrationOfficeCode",
    BrandType = "BrandType",
    ApplicationNumber = "ApplicationNumber",
    RegistrationNumber = "RegistrationNumber",
    BrandState = "BrandState",
    BrandStateCategory = "BrandStateCategory",
    RegistrationDate = "RegistrationDate",
    ApplicationDate = "ApplicationDate",
    PublicationDate = "PublicationDate",    
    OppositionPeriodEndDate = "OppositionPeriodEndDate",
    ExhibitionDate = "ExhibitionDate",
    ExhibitionName = "ExhibitionName",
    ExpiryDate = "ExpiryDate",
    BrandLogoTags = "BrandLogoTags",
    NiceClasses = "NiceClasses",
    ViennaClasses = "ViennaClasses",
    Deleted = "Deleted",
    LanguageCode = "LanguageCode",
}

export enum FacetableField {
    Name = "Name",
    BrandLogoText = "BrandLogoText",
    RegistrationOfficeCode = "RegistrationOfficeCode",
    BrandType = "BrandType",
    BrandState = "BrandState",
    BrandStateCategory = "BrandStateCategory",
    ExhibitionName = "ExhibitionName",
    BrandLogoTags = "BrandLogoTags",
    NiceClasses = "NiceClasses",
    ViennaClasses = "ViennaClasses",
}

export enum SortableField {
    RegistrationDate = "RegistrationDate",
    ApplicationDate = "ApplicationDate",
    OppositionPeriodEndDate = "OppositionPeriodEndDate",
    ExhibitionDate = "ExhibitionDate",
    ExpiryDate = "ExpiryDate"
}

// Methods:
export function getSearchableFields(): string[] {
    return Object.values(SearchableField);
}

export function getFilterableFields(): string[] {
    return Object.values(FilterableField);
}

export function getFacetableFields(): string[] {
    return Object.values(FacetableField);
}

export function getSortableFields(): string[] {
    return Object.values(SortableField);
}

export enum BrandQueryMode {
    Default = "Default",
    Custom = "Custom",
    ExactSearchUnion = "ExactSearchUnion",
    ExactSearchIntersect = "ExactSearchIntersect"
}

export enum AutocompleteMode {
    OneTerm = "OneTerm",
    TwoTerms = "TwoTerms",
    OneTermWithContext = "OneTermWithContext"
}

export enum BrandStateCategory {
    Alive = "Alive",
    Dead = "Dead",
    New = "New",
    Unknown = "Unknown"
}

export enum Comparator {
    Equals = "eq",
    NotEquals = "ne",
    GreaterThan = "gt",
    LowerThan = "lt",
    GreaterOrEquals = "ge",
    LowerOrEquals = "le",
}

export enum GoodsServicesQueryMode {
    KeywordSearchLucene = "KeywordSearchLucene",
    SemanticSearch = "SemanticSearch",
    FilterSearch = "FilterSearch",
    VectorSearch = "VectorSearch"
}